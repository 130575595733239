var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"synthesisActionModalContainer"},[_c('div',{staticClass:"closeIconContainer"},[_c('v-icon',{attrs:{"medium":""},on:{"click":_vm.handleCloseModal}},[_vm._v(_vm._s('mdi-close'))])],1),_c('div',{staticClass:"headingContainer"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),_c('div',{staticClass:"content"},[(
        _vm.modalType === 'download' ||
        _vm.modalType === 'slidesLibrary' ||
        (_vm.modalType === 'favorites' && !_vm.isAddedToLibrary)
      )?_c('div',{staticClass:"nameEditorAndLabel"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('generate.typeLabel', { type: _vm.capitalize(_vm.type) }))+" ")]),_c('div',{staticClass:"textField"},[_c('v-text-field',{attrs:{"readonly":_vm.operations},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.slideName.length > 0 && !_vm.operations)?_c('v-icon',{attrs:{"medium":"","color":"#21a7e0","placeholder":_vm.$t('generate.enterSlideName')},on:{"click":_vm.clearSlideName}},[_vm._v(" "+_vm._s('mdi-close-circle')+" ")]):_vm._e()]},proxy:true}],null,false,470808645),model:{value:(_vm.slideName),callback:function ($$v) {_vm.slideName=$$v},expression:"slideName"}})],1)]):_vm._e(),(_vm.modalType === 'favorites' && _vm.isAddedToLibrary)?_c('div',{staticClass:"favoritesText"},[_vm._v(" "+_vm._s(_vm.$t('generate.actionLabel', { typeLabel: _vm.type }))+" ")]):_vm._e(),(
        !_vm.isAddedToLibrary && _vm.modalType === 'download' && _vm.addToLibraryEnabled
      )?_c('div',{staticClass:"add-prez-lib"},[_c('v-checkbox',{attrs:{"label":_vm.type === 'slide'
            ? _vm.$t('generate.addSlideToLibrary')
            : _vm.$t('generate.addPrezentationToLibrary'),"color":"#20a7e0","hide-details":""},model:{value:(_vm.addToLibrary),callback:function ($$v) {_vm.addToLibrary=$$v},expression:"addToLibrary"}})],1):_vm._e()]),_c('div',{staticClass:"actions",style:(_vm.modalType === 'download' ? { margin: '0px 0px 12px 0px' } : {})},[(!_vm.operations || _vm.operation.loading)?_c('div',{staticClass:"buttons",style:(_vm.modalType === 'download' ? { width: '40%' } : {})},[_c('v-btn',{staticClass:"cancelBtn",on:{"click":_vm.handleCloseModal}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),(_vm.modalType === 'download')?_c('Download',{attrs:{"file-name":_vm.getFilename,"item":Object.assign({}, _vm.downloadItem, {name: ("" + _vm.slideName)}),"downloading":_vm.isDownloadingFileFromSnackbar,"log-download":_vm.logDownload,"count-first-time":1,"on-download":function () { return _vm.handleSlideDownload(); },"origin":"slide","limitsKey":"slide","isDisabled":_vm.isDownloadingFileFromSnackbar || _vm.slideName.length === 0,"speedDialDirection":'top',"speedDialPosTop":'-80',"speedDialPosRight":'0',"closePreviousPopups":function () { return _vm.closePreviousPopups(
              _vm.slideName,
              _vm.addToLibraryEnabled ? _vm.addToLibrary : false
            ); },"allowDownloadSettingsIcon":false}}):_c('v-btn',{staticClass:"primaryBtn",attrs:{"disabled":(!_vm.isAddedToLibrary && _vm.slideName === '') || _vm.operations,"loading":_vm.operation.loading},on:{"click":_vm.handlePrimaryButtonAction}},[_vm._v(" "+_vm._s(_vm.primaryText)+" ")])],1):_vm._e(),(_vm.operations && _vm.operation.success && !_vm.operation.loading)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":"#20a7e0"}},[_vm._v("mdi-check")]),_c('span',[_vm._v(_vm._s(_vm.successMessage))])],1):_vm._e(),(_vm.operations && !_vm.operation.success && !_vm.operation.loading)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":"#20a7e0","size":"18"}},[_vm._v("mdi-alert-outline")]),_c('span',[_vm._v(_vm._s(_vm.modalType === 'favorites' ? _vm.$t('premiumFeature.addToFavoritesFailed') : _vm.$t('premiumFeature.addToLibraryfailed')))])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }